<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" sm="8" md="10" lg="6" xl="4">
      <v-form ref="form">
        <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
          <h1 class="text-h1 font-weight-600 text-center mb-2 pt-5">
            Forgot Password?
          </h1>
          <p
            class="text-light font-size-root text-center font-weight-thin mb-8"
          >
            Please enter your email in order to send you the reset link.
          </p>
          <v-card-text class="font-size-root">
            <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block"
              >Email</label
            >
            <v-text-field
				id="email"
				:rules="[rules.required, rules.email]"
				v-model="email"
				autocomplete="off"
				hide-details="auto"
				validate-on-blur
				type="text"
				outlined
				class="input-style font-size-input text-color-gray mt-0 mb-4"
				placeholder="johndoe@gmail.com"
            >
            </v-text-field>

            <v-btn
				@click="sendResetPassword"
				elevation="0"
				block
				:loading="isLoading"
				:disabled="disabled"
				height="43"
				class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
				color="#6F70FF"
              >Send Link {{ seconds }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-center mt-4">
        <small class="text-light">Back to
          <router-link
            to="login"
            class="text-decoration-none"
          >
            <span class="text-primary">Login</span>
          </router-link>
        </small>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import { authManagement } from '@/store/api'

const TIMEOUT = 10

export default {
  name: "forgotPassword",
  data() {
    return {
        email: '',
		isLoading: false,
		disabled: false,
		times: 0,
		resendInterval: null,
		secondsToEnable: TIMEOUT,
		seconds: '',
        rules: {
            required: (value) => (value && Boolean(value)) || '*This field is required',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return pattern.test(value) || 'Invalid e-mail.'
            }
        }
    };
  },
  beforeDestroy() {
		clearInterval(this.resendInterval)
	},
  methods: {
    async sendResetPassword() {
		if (this.$refs.form.validate()) {
			this.setTimer()
			try {
				await authManagement.create({
				action: 'sendResetPwd',
				value: { email: this.email }
				})

				this.showSuccess('Email sent!')
			} catch (error) {
				this.showError(error)
			}
		}
    },
    async resendLink() {
		if (this.$refs.form.validate()) {
			this.setTimer()

			try {
				await authManagement.create({
				action: 'resendVerifySignup',
				value: { email: this.email }
				})
			} catch (error) {
				this.showError(error)
			}
		}
    },
    setTimer() {
        this.disabled = true
        this.times++
        this.secondsToEnable = TIMEOUT * this.times

        this.resendInterval = setInterval(() => {
            if (this.secondsToEnable === 0) {
                clearInterval(this.resendInterval)
                this.seconds = ''
                this.disabled = false
            } else {
                this.seconds = `( ${this.secondsToEnable} )`
                this.secondsToEnable--
            }
        }, 1000)
    },
    ...mapActions('app', ['showSuccess', 'showError'])
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
